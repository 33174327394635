<template>
  <div class="container w-full h-full grid grid-cols-12">
    <div class="col-span-9">
      <!-- <div class="text-6xl text-red-500" v-if="isOwner">
        SEI IL PROPRIETARIO
      </div> -->

      <div id="class-container"></div>
      <div id="remote-container" class="grid grid-cols-3 gap-4"></div>
      <div id="local-container" class="border w-full h-auto"></div>
      <div class="text-white bg-red-500">{{ errors }}</div>
      <button @click="hang" class="px-6 py-2 bg-secondary-500 text-white">
        Chiudi
      </button>
      <button @click="init" class="px-6 py-2 bg-secondary-500 text-white">
        Join
      </button>
    </div>
    <div class="col-span-3 flex flex-col h-full bg-gray-100 px-4 py-8">
      <div class="flex-grow">
        <ul>
          <li v-for="(msg, m) in chatBoard" :key="m">
            <span v-if="msg.sender">
              <span class="text-medium text-secondary-500"
                >{{ msg.sender.userData.username }}: </span
              >{{ msg.content }}
            </span>
            <span
              v-else
              v-html="msg"
              class="text-right w-full flex justify-end"
            >
            </span>
          </li>
        </ul>
      </div>
      <form
        @submit.prevent="sendMessageToActiveConversation"
        class="flex justify-center bg-white rounded-md border border-gray-400"
      >
        <input
          type="text"
          v-model="chatMessage"
          placeholder="Scrivi messaggio"
          class="bg-transparent px-4 focus:outline-none w-full"
        />
        <button type="submit" class="bg-gray-100 py-2 px-4 rounded-r-md">
          <i class="mdi mdi-arrow-right text-secondary-500 text-xl"></i>
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// @ is an alias to /src
// const apiRTC = this.$apirtc || window.apiRTC;

export default {
  name: "Live",
  data() {
    return {
      //apiRTC: this.$apirtc || window.apiRTC,
      ua: null,
      room: "",
      company_id: "",
      connectedSession: null,
      connectedConversation: null,
      localStream: null,
      createStreamOptions: {},
      errors: "",
      isOwner: false,
      userType: "",
      chatBoard: [],
      chatMessage: "",

      username: "HIVEGUEST"
    };
  },
  mounted() {
    // controllo se lo user è loggato
    // this.getUserType();
    // this.init();
  },
  computed: {
    ...mapGetters("auth", ["isLogged", "isMerchant", "companyId"])
  },
  methods: {
    getUserType() {
      if (this.isLogged) {
        this.userType = "REGISTERED";
        if (this.isMerchant) {
          this.userType = "MERCHANT";
          if (this.$route.query.company_id == this.companyId) {
            console.log("VAI LIVE!!!");
            this.userType = "OWNER";
          }
        }
      } else {
        this.userType = "GUEST";
      }
    },
    init() {
      //let username = "HIVEGUEST";
      // this.ua = new this.apiRTC.UserAgent({
      //   uri: "apzkey:e502bb509d6341348f31b4d059dc0595"
      // });
      // console.log(
      //   "Username: ",
      //   this.$store.state.auth.currentUserData.attributes.name
      // );
      // this.$store.state.auth.currentUserData.attributes.name || "guest";
      // this.ua.setUsername(username);
      // this.room = this.$route.query.room;
      // this.company_id = this.$route.query.company_id;
      // if (this.room) {
      //   this.joinRoom();
      // }
    },

    joinRoom() {
      // var self = {};
      console.log("Joining room: ", this.room);
      this.ua
        .register({
          userData: {
            username: this.isLogged
              ? this.$store.state.auth.currentUserData.attributes.name
              : null,
            company_id: this.company_id
          }
        })
        .then(session => {
          console.log("Ottengo la sessione ", session);
          this.connectedSession = session;
          this.connectedSession.on(
            "contactListUpdate",
            this.onContactListUpdate
          );
          this.connectedConversation = session.getConversation(this.room);
          let streamOption = {
            costraints: {
              audio: true,
              video: true
            }
          };
          this.connectedConversation.on(
            "streamListChanged",
            this.onStreamListChanged
          );

          this.connectedConversation
            .on("streamAdded", this.onStreamAdded)
            .on("streamRemoved", this.onStreamRemoved);

          this.ua.createStream(streamOption).then(stream => {
            this.localStream = stream;
            let owner = stream.getOwner();

            console.log("Owner of this ", owner);
            console.log("My Company Id ", this.companyId);
            console.log(
              "Stream Company Id ",
              owner.preRegisterUserData.company_id
            );

            if (this.companyId === owner.preRegisterUserData.company_id) {
              console.log("Trasmetti Live");
              stream.addInDiv(
                "local-container",
                "localmedia",
                {
                  width: "100%",
                  height: "50vh",
                  "object-fit": "cover"
                },
                true
              );
            } else {
              console.log("vai sotto");
              stream.addInDiv(
                "remote-container",
                "remote-media-" + stream.streamId,
                {
                  width: "100%",
                  height: "50vh",
                  "object-fit": "cover"
                },
                true
              );
            }

            this.connectedSession.on(
              "contactListUpdate",
              this.onContactListUpdate
            );

            this.connectedConversation.on("message", e => {
              console.log("Messaggio ricevuto ", e);
              this.chatBoard.push(e);
            });

            this.connectedConversation.join().then(() => {
              this.connectedConversation.publish(stream);
            });
          });
        });
    },
    onContactListUpdate(updatedContacts) {
      console.log("MAIN - contactListUpdate", updatedContacts);
      if (this.connectedConversation !== null) {
        let contactList = this.connectedConversation.getContacts();
        console.info(
          "contactList  connectedConversation.getContacts() :",
          contactList
        );
      }
    },

    onStreamListChanged(streamInfo) {
      console.log("streamListChanged :", streamInfo);
      //this.isOwner = !!streamInfo.contact.userData.id;
      if (streamInfo.listEventType === "added") {
        if (streamInfo.isRemote === true) {
          this.connectedConversation
            .subscribeToMedia(streamInfo.streamId)
            .then(function(stream) {
              console.log("subscribeToMedia success", stream);
            })
            .catch(function(err) {
              console.error("subscribeToMedia error", err);
              this.errors = err;
            });
        }
      }
    },

    onStreamAdded(stream) {
      let owner = stream.getOwner();
      console.log("Owner ", owner);
      if (this.companyId == owner.userData.company_id) {
        stream.addInDiv(
          "local-container",
          "localmedia",
          {
            width: "100%",
            height: "50vh",
            "object-fit": "cover"
          },
          true
        );
      } else {
        stream.addInDiv(
          "remote-container",
          "remote-media-" + stream.streamId,
          {},
          true
        );
      }
      // let owner = stream.getOwner();
      // this.isOwner = !!owner;
    },

    onStreamRemoved(stream) {
      stream.removeFromDiv(
        "remote-container",
        "remote-media-" + stream.streamId
      );
    },
    sendMessageToActiveConversation() {
      try {
        this.connectedConversation.sendMessage(this.chatMessage);
        this.chatBoard.push(
          "<span class='text-primary-500'>You: </span> " + this.chatMessage
        );
      } catch (error) {
        console.log("Errore della chat", error);
      }
      this.chatMessage = "";
    },
    hang() {
      this.connectedSession.disconnect().then(c => {
        console.log("Disconnesso ", c);

        this.connectedConversation.leave();
        this.localStream.release();
      });
    }
  },
  beforeDestroy() {
    this.hang();
  }
};
</script>
<style lang="postcss">
input:focus,
button:focus {
  @apply outline-none;
}
#remote-container video {
  @apply: w-full h-full object-contain;
}
</style>
